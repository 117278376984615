import React, { FC } from "react"
import styled from "styled-components"

const Container = styled.div`
    color: ${({ theme }) => theme.text.normal};

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: ${({ theme }) => theme.text.dark};
        font-family: "Gilroy-Medium", sans-serif;
    }

    > .head {
        margin-bottom: 100rem;
    }

    > .body {
        p {
            font-size: 16rem;
            line-height: 25rem;
        }
    }
`

export const TextDocument: FC = ({ children }) => {
    return <Container>{children}</Container>
}
